import React, { useContext, useMemo } from "react";
import { createContext } from "react";
import { useParams } from "react-router";
import { CherryPlayApi } from "../api/cherryplayModels";
import { ErrorMessage } from "../components/ErrorMessage/ErrorMessage";
import { LoadingIndicator } from "../components/LoadingIndicator/LoadingIndicator";
import { ApiRequestState, useApiRequest } from "../hooks/useApiRequest";

interface TModelContext {
  business: ApiRequestState<CherryPlayApi.BusinessBasic | null>;
  member: ApiRequestState<CherryPlayApi.Member>;
}

const ModelContext = createContext<TModelContext | null>(null);

/**
 * Loads models from the api based on the presence of react-router path parameters. Example: if
 * the `businessId` path parameter is set, this component will display loading & error states and
 * then inject the business into the context once loaded.
 */
export const ModelContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { businessId, memberId } = useParams();

  const businessRequest = useApiRequest(
    (apiClient) => {
      if (!businessId) {
        return null;
      }
      return apiClient.getBusiness(businessId);
    },
    [businessId]
  );

  const memberRequest = useApiRequest(
    (apiClient) => {
      if (!businessId || !memberId) {
        return null;
      }
      return apiClient.getMember(businessId, memberId);
    },
    [businessId, memberId]
  );

  const { isLoading, error, notFound } = useMemo(
    () => ({
      isLoading:
        (!!businessId && businessRequest.isLoading) ||
        (!!memberId && memberRequest.isLoading),
      error:
        (!!businessId ? businessRequest.error : null) ||
        (!!memberId ? memberRequest.error : null),
      notFound:
        (!!businessId && !businessRequest.isLoading && !businessRequest.data) ||
        (!!memberId && !memberRequest.isLoading && !memberRequest.data),
    }),
    [businessId, businessRequest, memberId, memberRequest]
  );

  const context = useMemo(
    () => ({ business: businessRequest, member: memberRequest }),
    [businessRequest, memberRequest]
  );

  if (isLoading) {
    return <LoadingIndicator />;
  }

  if (error || notFound) {
    return (
      <ErrorMessage>
        An error was encounterd while loading the page
      </ErrorMessage>
    );
  }

  return (
    <ModelContext.Provider value={context}>{children}</ModelContext.Provider>
  );
};

/**
 * Returns the business loaded from the `:businessId` path parameter
 */
export const useBusinessContext = (): CherryPlayApi.BusinessBasic => {
  const ctx = useContext(ModelContext);

  if (!ctx || !ctx.business.data) {
    throw new Error("Missing business context");
  }

  return ctx.business.data;
};

/**
 * Returns the member loaded from the `:memberId` path parameter
 */
export const useMemberContext = (): CherryPlayApi.Member => {
  const ctx = useContext(ModelContext);

  if (!ctx || !ctx.member.data) {
    throw new Error("Missing member context");
  }

  return ctx.member.data;
};
