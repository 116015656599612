import React, { useCallback, useState } from "react";
import { PageContent } from "../../components/PageContent/PageContent";
import { RoundedBundle } from "../../components/Promotion/RoundedBundle";
import { useBusinessContext } from "../../context/ModelContext";
import { Link } from "react-router-dom";
import { BusinessBreadcrumbs } from "../../components/Breadcrumbs/Breadcrumbs";
import { BreadcrumbItem, BreadcrumbLink } from "@chakra-ui/breadcrumb";
import { Box, Flex } from "@chakra-ui/layout";
import { useApiRequest } from "../../hooks/useApiRequest";
import { NewPromotionModal } from "../../components/Promotion/NewPromotionModal";
import { PageHeading } from "../../components/PageHeading/PageHeading";
import { CherryPlayApi } from "../../api/cherryplayModels";

export const NewPromotion = () => {
  const business = useBusinessContext();

  const [isOpen, setIsOpen] = useState(false);
  const [bundleId, setBundleId] = useState("");
  const [bundleName, setBundleName] = useState("");

  const onClose = useCallback(() => setIsOpen(false), [setIsOpen]);

  const onBundleSelection = (bundle: CherryPlayApi.BundleDocument) => {
    setIsOpen(true);
    setBundleId(bundle.id);
    setBundleName(bundle.Name);
  };

  const { data: bundles } = useApiRequest((apiClient) => {
    return apiClient.getBundles();
  }, []);

  return (
    <>
      <PageHeading>
        <BusinessBreadcrumbs>
          <BreadcrumbItem>
            <BreadcrumbLink
              as={Link}
              to={`/businesses/${business.id}/promotions`}
            >
              Promotions
            </BreadcrumbLink>
          </BreadcrumbItem>
        </BusinessBreadcrumbs>
        <PageHeading.Title>Create Promotion</PageHeading.Title>
      </PageHeading>
      <PageContent>
        <Box maxWidth="100%" mx="auto" mt="40px">
          {bundles && bundles.Results.length > 0 && (
            <Flex
              flexWrap="wrap"
              justifyContent="flex-start"
              alignItems="center"
            >
              {bundles.Results.map((bundle) => (
                <Box
                  key={bundle.id}
                  width="350px"
                  p="10px"
                  onClick={() => onBundleSelection(bundle)}
                >
                  <RoundedBundle
                    imageUrl="https://via.placeholder.com/300x200"
                    name={bundle.Name}
                    description={bundle.Description}
                  />
                </Box>
              ))}
            </Flex>
          )}
        </Box>
      </PageContent>
      <NewPromotionModal
        onClose={onClose}
        isOpen={isOpen}
        bundleId={bundleId}
        bundleName={bundleName}
        businessId={business.id}
      />
    </>
  );
};
