import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
} from "@chakra-ui/breadcrumb";
import { VStack, Heading, Text } from "@chakra-ui/layout";
import React from "react";
import { Link } from "react-router-dom";
import { PageContent } from "../../components/PageContent/PageContent";
import { PageHeading } from "../../components/PageHeading/PageHeading";
import { useBusinessContext } from "../../context/ModelContext";
import { useUserContext } from "../../context/UserContext";
import { User } from "firebase/auth";
import { Card } from "../../components/Card/Card";
import { CherryPlayApi } from "../../api/cherryplayModels";

const BusinessSummary = ({
  business,
  user,
}: {
  business: CherryPlayApi.BusinessBasic;
  user: User | null;
}) => {
  return (
    <Card w="100%" minH="500px">
      <VStack w="100%" spacing="2" alignItems="start">
        <Text>Welcome, {user?.displayName ?? user?.email}</Text>
        <Heading>{business.DisplayName}</Heading>
        <Text>ABN: {business.id}</Text>
      </VStack>
    </Card>
  );
};

export const Business = () => {
  const { userBusinessId, user } = useUserContext();
  const business = useBusinessContext();

  const pageTitle = "";

  return (
    <>
      <PageHeading>
        <Breadcrumb>
          {!userBusinessId && (
            <BreadcrumbItem>
              <BreadcrumbLink as={Link} to="/businesses">
                Businesses
              </BreadcrumbLink>
            </BreadcrumbItem>
          )}
          {!userBusinessId && (
            <BreadcrumbItem>
              <BreadcrumbLink as={Link} to={`/businesses/${business.id}`}>
                {business.DisplayName}
              </BreadcrumbLink>
            </BreadcrumbItem>
          )}
        </Breadcrumb>
        <PageHeading.Title>{pageTitle}&nbsp;</PageHeading.Title>
      </PageHeading>
      <PageContent>
        <BusinessSummary user={user} business={business} />
      </PageContent>
    </>
  );
};
