import React, { memo } from "react";
import { CopyIcon, DeleteIcon } from "@chakra-ui/icons";
import { Box } from "@chakra-ui/layout";
import { IconButton } from "@chakra-ui/react";
import { Table, Tbody, Td, Th, Tr, Thead } from "@chakra-ui/table";
import { EditIcon } from "../../styles/icons";
import { LoadingIndicator } from "../LoadingIndicator/LoadingIndicator";
import { CherryPlayApi } from "../../api/cherryplayModels";

interface TermsConditionsTableProps {
  venues: any[] | null;
  business: CherryPlayApi.BusinessBasic;
  isLoading: boolean;
  disableActions?: boolean;
  onClickEditVenue: (venue: CherryPlayApi.VenueDocument) => void;
  onClickDeleteVenue: (venue: CherryPlayApi.VenueDocument) => void;
  onClickCloneVenue: (venue: CherryPlayApi.VenueDocument) => void;
}

export const TermsConditionsTable = memo(
  ({
    venues,
    business,
    isLoading,
    disableActions,
    onClickEditVenue,
    onClickDeleteVenue,
    onClickCloneVenue,
  }: TermsConditionsTableProps) => {
    return (
      <Box w="100%" overflowX="auto">
        <Table size="sm">
          <Thead>
            <Tr>
              <Th>Name of Promotion</Th>
              <Th>Game Type</Th>
              <Th>Link</Th>
              <Th></Th>
            </Tr>
          </Thead>
          <Tbody>
            {(venues ?? []).map((venue) => (
              <Tr key={venue.id}>
                <Td>{venue.name}</Td>
                <Td>{venue.gameType}</Td>
                <Td>
                  <a
                    href={`/view/${business.id}/terms-conditions/${venue.id}`}
                    target="_blank"
                    style={{ textDecoration: "underline" }}
                  >
                    Link
                  </a>
                </Td>
                <Td width="0.1%" whiteSpace="nowrap">
                  <IconButton
                    aria-label="Edit"
                    title="Edit"
                    icon={<EditIcon />}
                    size="sm"
                    isDisabled={disableActions}
                    onClick={() => onClickEditVenue(venue)}
                  />
                  {venue.createdAt && (
                    <IconButton
                      marginLeft="1"
                      aria-label="Delete"
                      title="Delete"
                      icon={<DeleteIcon />}
                      size="sm"
                      isDisabled={disableActions}
                      onClick={() => onClickDeleteVenue(venue)}
                    />
                  )}
                  {venue.createdAt && (
                    <IconButton
                      marginLeft="1"
                      aria-label="Clone"
                      title="Clone"
                      icon={<CopyIcon />}
                      size="sm"
                      isDisabled={disableActions}
                      onClick={() => onClickCloneVenue(venue)}
                    />
                  )}
                </Td>
              </Tr>
            ))}
            {venues?.length === 0 && !isLoading && (
              <Tr>
                <Td colSpan={3} textAlign="center">
                  Nothing created.
                </Td>
              </Tr>
            )}
            {(!venues || isLoading) && (
              <Tr>
                <Td colSpan={3} textAlign="center">
                  <LoadingIndicator color="gray.600" />
                </Td>
              </Tr>
            )}
          </Tbody>
        </Table>
      </Box>
    );
  }
);
