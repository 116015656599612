import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
} from "@chakra-ui/breadcrumb";
import React from "react";
import { Link } from "react-router-dom";
import { useBusinessContext } from "../../context/ModelContext";
import { useUserContext } from "../../context/UserContext";

interface BusinessBreadcrumbs {
  children: React.ReactNode;
}
export const BusinessBreadcrumbs = ({ children }: BusinessBreadcrumbs) => {
  const business = useBusinessContext();
  const { userBusinessId } = useUserContext();
  return (
    <Breadcrumb>
      {/* Users not scoped to a business can navigate to the full business list. */}
      {!userBusinessId && (
        <BreadcrumbItem>
          <BreadcrumbLink as={Link} to="/businesses">
            Businesses
          </BreadcrumbLink>
        </BreadcrumbItem>
      )}
      <BreadcrumbItem>
        <BreadcrumbLink as={Link} to={`/businesses/${business.id}`}>
          {business?.DisplayName}
        </BreadcrumbLink>
      </BreadcrumbItem>
      {children}
    </Breadcrumb>
  );
};
