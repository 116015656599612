import { useParams } from "react-router-dom";
import { Box } from "@chakra-ui/react";
import React, { useCallback, useEffect, useState } from "react";
import { useApiClient } from "../../hooks/useApiClient";
import { PageContent } from "../PageContent/PageContent";
import { useFirebase } from "../../context/FirebaseContext";
import { LoadingIndicator } from "../LoadingIndicator/LoadingIndicator";
import { TermsConditionsDisplay } from "../TermsConditionsDisplay/TermsConditionsDisplay";

export const TermsConditionsView = () => {
  const apiClient = useApiClient();
  const { db } = useFirebase();
  const { businessId, termsId } = useParams();
  const [item, setItem] = useState<any>(null);

  const getItem = useCallback(async () => {
    if (termsId) {
      const fetchedItem = await apiClient.getTermsConditionsSingle(
        db,
        businessId ?? "",
        termsId
      );
      console.log(
        "aaaaa",
        { db, businessId: businessId, termsId },
        fetchedItem.data()
      );
      if (fetchedItem.exists()) {
        setItem({ ...fetchedItem.data(), id: fetchedItem.id });
      }
    }
  }, [termsId]);

  useEffect(() => {
    getItem();
  }, [termsId]);

  const css = `
    html, body {
      background: #FAFAFA;
    }
    
  `;

  return (
    <>
      <style>{css}</style>
      <PageContent>
        {item && (
          <Box mx="20px" p="15px" borderRadius="10px" maxW="1024px">
            <TermsConditionsDisplay item={item} />
          </Box>
        )}

        {!item && <LoadingIndicator color="gray.600" />}
      </PageContent>
    </>
  );
};
