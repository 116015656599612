import { Link, useNavigate, useParams } from "react-router-dom";
import { Flex, Container, Box, useMediaQuery } from "@chakra-ui/react";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useBusinessContext } from "../../context/ModelContext";
import { useApiClient } from "../../hooks/useApiClient";
import { useToast } from "../../hooks/useToast";
import { BusinessBreadcrumbs } from "../Breadcrumbs/Breadcrumbs";
import { PageContent } from "../PageContent/PageContent";
import { PageHeading } from "../PageHeading/PageHeading";
import { TermsConditionsForm } from "../../forms/TermsConditionsForm";
import { useFirebase } from "../../context/FirebaseContext";
import { LoadingIndicator } from "../LoadingIndicator/LoadingIndicator";
import { TermsConditionsDisplay } from "../TermsConditionsDisplay/TermsConditionsDisplay";
import { DocumentSnapshot } from "firebase/firestore";

export const TermsConditionsPage = () => {
  const business = useBusinessContext();
  const apiClient = useApiClient();
  const { showErrorToast, showSuccessToast } = useToast();
  const { db } = useFirebase();
  const { termsId } = useParams();
  const [item, setItem] = useState<any>(null);
  const [itemKey, setItemKey] = useState<any>(100);

  const navigate = useNavigate();

  const onCancel = useCallback(() => {
    navigate(`/businesses/${business.id}/terms-conditions`);
  }, []);

  const onSuccess = useCallback(
    (result?: DocumentSnapshot, goToList?: boolean) => {
      if (result?.id && !goToList) {
        window.location.href = `/businesses/${business.id}/terms-conditions/${result.id}`;
      } else if (goToList) {
        navigate(`/businesses/${business.id}/terms-conditions`);
      } else {
        showSuccessToast("Saved successfully");
        getItem();
      }
    },
    [showSuccessToast]
  );

  const onFailure = useCallback(
    (message?: string) => {
      showErrorToast(message ?? "Error occurred");
    },
    [showErrorToast]
  );

  const getItem = useCallback(async () => {
    if (termsId) {
      const fetchedItem = await apiClient.getTermsConditionsSingle(
        db,
        business.id,
        termsId
      );
      console.log(
        "aaaaa",
        { db, businessId: business.id, termsId },
        fetchedItem.data()
      );
      if (fetchedItem.exists()) {
        setItem({ ...fetchedItem.data(), id: fetchedItem.id });
        setItemKey(Math.random());
        console.log(Math.random());
      }
    }
  }, [termsId]);

  useEffect(() => {
    getItem();
  }, [termsId]);

  return (
    <>
      <PageHeading>
        <BusinessBreadcrumbs>{}</BusinessBreadcrumbs>
        <PageHeading.Title>Game Terms</PageHeading.Title>
      </PageHeading>
      <PageContent>
        {(item || !termsId) && (
          <Flex alignContent="space-evenly" w="100%" justifyContent="center">
            <Box flexBasis="0" flexGrow={1} maxW="600px">
              <TermsConditionsForm
                venue={item}
                apiClient={apiClient}
                db={db}
                business={business}
                onCancel={onCancel}
                onSuccess={onSuccess}
                onFailure={onFailure}
              />
            </Box>
            {item && (
              <Container
                display={["none", "none", "block"]}
                flexBasis="0"
                flexGrow={1}
                maxW="none"
              >
                <Box m="20px" p="15px" borderRadius="10px">
                  <TermsConditionsDisplay key={itemKey} item={item} />
                </Box>
              </Container>
            )}
          </Flex>
        )}

        {termsId && !item && <LoadingIndicator color="gray.600" />}
      </PageContent>
    </>
  );
};
