import React, { useMemo, useCallback } from "react";
import { Card } from "../../components/Card/Card";
import { Box, VStack, HStack, Center, LinkBox, Text } from "@chakra-ui/layout";
import { Spinner } from "@chakra-ui/spinner";
import { Button, IconButton } from "@chakra-ui/button";
import { Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/table";
import { SearchInput } from "../../components/SearchInput/SearchInput";
import { PlusIcon } from "../../styles/icons";
import { PageHeading } from "../../components/PageHeading/PageHeading";
import { BusinessBreadcrumbs } from "../../components/Breadcrumbs/Breadcrumbs";
import { BreadcrumbItem, BreadcrumbLink } from "@chakra-ui/breadcrumb";
import { Link, useNavigate } from "react-router-dom";
import { useBusinessContext } from "../../context/ModelContext";
import { PageContent } from "../../components/PageContent/PageContent";
import { usePaginatedApiRequest } from "../../hooks/usePaginatedApiRequest";
import { useSearchInputState } from "../../hooks/useSearchInputState";
import { EditIcon, TrashIcon } from "../../styles/icons";
import { ErrorMessage } from "../../components/ErrorMessage/ErrorMessage";

export const PromotionList = ({ businessId }: { businessId: string }) => {
  const navigate = useNavigate();
  const { query, debouncedQuery, onChange, isDebouncing } =
    useSearchInputState("");

  const {
    items: promotions,
    isLoading,
    isLoadingPage,
    error,
    fetchNextPage,
  } = usePaginatedApiRequest(
    // Request the first page
    (apiClient) => apiClient.searchPromotions(businessId, debouncedQuery),
    // Request subsequent pages
    (apiClient, nextUrl) => apiClient.searchPromotionsPage(nextUrl),
    [businessId, debouncedQuery]
  );

  const listItems = useMemo(
    () =>
      (promotions ?? []).map((promotion) => ({
        promotion,
      })),
    [promotions]
  );

  const onClickNewPromotion = useCallback(() => navigate("./new"), []);

  return (
    <Card width="100%">
      <VStack width="100%" spacing="4" alignItems="start">
        <HStack width="100%">
          <Box flex="1">
            <Box width="100%" maxWidth="300px">
              <SearchInput
                value={query}
                onChange={onChange}
                isLoading={isLoading || isDebouncing}
                placeholder="Search promotions..."
              />
            </Box>
          </Box>
          <Button
            leftIcon={<PlusIcon />}
            alignSelf="end"
            onClick={onClickNewPromotion}
            colorScheme="cherryButton"
          >
            New Promotion
          </Button>
        </HStack>
        {!promotions && isLoading && (
          <Center>
            <Spinner margin="4" />
          </Center>
        )}
        {promotions && promotions.length > 0 && (
          <Box w="100%" overflowX="auto">
            <Table size="sm" minW="600px">
              <Thead>
                <Tr>
                  <Th>Promotion No</Th>
                  <Th>Name</Th>
                  <Th>Campaign URL</Th>
                  <Th></Th>
                </Tr>
              </Thead>
              <Tbody>
                {listItems.map(({ promotion }) => (
                  <LinkBox as={Tr} key={promotion.id} transform="scale(1)">
                    <Td>{promotion.PromotionNo}</Td>
                    <Td>{promotion.DisplayName ?? "-"}</Td>
                    <Td>{promotion.CampaignURL}</Td>
                    <Td width="0.1%" whiteSpace="nowrap">
                      <IconButton
                        aria-label="Edit"
                        icon={<EditIcon />}
                        size="sm"
                      />
                      <IconButton
                        marginLeft="1"
                        aria-label="Delete"
                        icon={<TrashIcon />}
                        size="sm"
                      />
                    </Td>
                  </LinkBox>
                ))}
              </Tbody>
            </Table>
          </Box>
        )}
        {!isLoading && promotions && promotions.length === 0 && (
          <Text>No promotion found matching query "{debouncedQuery}"</Text>
        )}

        {fetchNextPage && (
          <Box>
            {!isLoadingPage && (
              <Button onClick={fetchNextPage}>Load more...</Button>
            )}
            {isLoadingPage && <Spinner />}
          </Box>
        )}

        {error != null && (
          <ErrorMessage>
            An error was encountered while searching promotions. {error}
          </ErrorMessage>
        )}
      </VStack>
    </Card>
  );
};

export const Promotions = () => {
  const business = useBusinessContext();

  return (
    <>
      <PageHeading>
        <BusinessBreadcrumbs>
          <BreadcrumbItem>
            <BreadcrumbLink
              as={Link}
              to={`/businesses/${business.id}/promotions`}
            >
              Promotions
            </BreadcrumbLink>
          </BreadcrumbItem>
        </BusinessBreadcrumbs>
        <PageHeading.Title>Promotions</PageHeading.Title>
      </PageHeading>
      <PageContent>
        <PromotionList businessId={business.id} />
      </PageContent>
    </>
  );
};
