import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, HStack } from "@chakra-ui/layout";
import { PageContent } from "../../components/PageContent/PageContent";
import { PageHeading } from "../../components/PageHeading/PageHeading";
import { useBusinessContext } from "../../context/ModelContext";
import { LoadingIndicator } from "../../components/LoadingIndicator/LoadingIndicator";
import { useApiClient } from "../../hooks/useApiClient";
import { useModalState } from "../../hooks/useModalState";
import { Button } from "@chakra-ui/react";
import { PlusIcon } from "../../styles/icons";
import { useToast } from "../../hooks/useToast";
import { useConfirmationDialog } from "../../hooks/useConfirmationDialog";
import { TermsConditionsTable } from "../../components/TermsConditionsTable/TermsConditionsTable";
import { useFirebase } from "../../context/FirebaseContext";
import { CherryPlayApi } from "../../api/cherryplayModels";

export const TermsConditionsList = () => {
  const business = useBusinessContext();
  const apiClient = useApiClient();
  const { db } = useFirebase();
  const navigate = useNavigate();

  const { showErrorToast, showSuccessToast } = useToast();
  const { confirmationElement, showConfirmationDialog } =
    useConfirmationDialog();

  const modalState = useModalState<CherryPlayApi.VenueDocument | null>();

  const [items, setItems] = useState<any[] | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const getData = useCallback(async () => {
    const result = await apiClient.getTermsConditionsList(db, business.id);
    console.log(result.docs);
    setItems(result.docs.map((s) => ({ ...s.data(), id: s.id })));
    setIsLoading(false);
  }, [db]);

  useEffect(() => {
    getData();
  }, []);

  const onClickNewVenue = useCallback(
    () => navigate("./new"),
    [modalState.onOpen]
  );

  const onClickEditVenue = useCallback(
    (venue: CherryPlayApi.VenueDocument) => {
      navigate("./" + venue.id);
    },
    [modalState.onOpen]
  );

  const onClickDeleteVenue = useCallback(
    (venue: CherryPlayApi.VenueDocument) =>
      showConfirmationDialog(
        "Delete?",
        "Are you sure you wish to delete this record?",
        "Delete"
      ).then(async ({ ok }) => {
        if (ok) {
          await apiClient.deleteTermsConditions(db, business.id, venue.id);
          showSuccessToast("Deleted successfully.");
          getData();
        }
      }),
    [showSuccessToast, showErrorToast, showConfirmationDialog, apiClient]
  );

  const onClickCloneVenue = useCallback(
    (venue: CherryPlayApi.VenueDocument) =>
      showConfirmationDialog(
        "Clone?",
        "Are you sure you wish to clone this record?",
        "Clone"
      ).then(async ({ ok }) => {
        if (ok) {
          await apiClient.cloneTermsConditions(db, business.id, venue.id);
          showSuccessToast("Cloned successfully.");
          getData();
        }
      }),
    [showSuccessToast, showErrorToast, showConfirmationDialog, apiClient]
  );

  return (
    <>
      <PageHeading>
        <PageHeading.Title>Game Terms</PageHeading.Title>
      </PageHeading>
      <PageContent>
        <HStack w="100%">
          <Box marginLeft="auto">
            <Button
              leftIcon={<PlusIcon />}
              onClick={onClickNewVenue}
              colorScheme="cherryButton"
            >
              New Item
            </Button>
          </Box>
        </HStack>

        <TermsConditionsTable
          venues={items}
          business={business}
          isLoading={!items && isLoading}
          disableActions={isLoading}
          onClickEditVenue={onClickEditVenue}
          onClickDeleteVenue={onClickDeleteVenue}
          onClickCloneVenue={onClickCloneVenue}
        />

        {isLoading && <LoadingIndicator />}

        {confirmationElement}
      </PageContent>
    </>
  );
};
