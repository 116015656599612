import { CherryPlayApi } from "./cherryplayModels";
import axios, { Axios, AxiosResponse } from "axios";
import { User } from "firebase/auth";
import {
  addDoc,
  collection,
  doc,
  Firestore,
  query,
  setDoc,
  getDoc,
  DocumentSnapshot,
  getDocs,
  QuerySnapshot,
  DocumentData,
  orderBy,
  where,
  DocumentReference,
} from "firebase/firestore";

export type ApiError = { statusCode?: number; message: string; ok: false };
export type ApiResult<T> =
  | { statusCode: number; data: T; ok: true; next?: string }
  | ApiError;

const apiResult = <T>(
  axiosResponse: AxiosResponse,
  getData?: (data: any) => T
): ApiResult<T> => {
  if (axiosResponse.status >= 200 && axiosResponse.status <= 299) {
    // If the response body is paginated we'll find a url for the next page.
    const next = axiosResponse.data?._links?.next?.relativeUrl ?? undefined;

    return {
      data: (getData ? getData(axiosResponse.data) : axiosResponse.data) as T,
      statusCode: axiosResponse.status,
      ok: true,
      next,
    };
  } else if (axiosResponse.status) {
    return {
      statusCode: axiosResponse.status,
      message:
        axiosResponse.data?.ErrorMessage ??
        `HTTP ${axiosResponse.status} error.`,
      ok: false,
    };
  } else {
    return {
      statusCode: axiosResponse.status,
      message: `Unknown error.`,
      ok: false,
    };
  }
};

export class ApiClient {
  cherryPlayClient: Axios;

  constructor(user?: User) {
    this.cherryPlayClient = axios.create({
      baseURL: `${API_BASE_URL}`,
      // HTTP errors will not throw exceptions
      validateStatus: () => true,
    });

    if (user) {
      this.cherryPlayClient.interceptors.request.use((config) => {
        // Fetches the current access token. If it has expired or is close to exiring a new token will be fetched.
        return user.getIdTokenResult().then((idToken) => {
          if (!config.headers) {
            config.headers = {};
          }
          config.headers!.Authorization = `Bearer ${idToken.token}`;
          return config;
        });
      });
    }
  }

  /**
   * Returns an individual business summary
   * @returns
   */
  async getBusiness(
    businessId: string
  ): Promise<ApiResult<CherryPlayApi.BusinessBasic | null>> {
    const result = await this.cherryPlayClient.get(
      `/cherryplay/v1/business/${businessId}`
    );

    return apiResult(result);
    // const findBusinessInResults = (data: any) =>
    //   data.find(
    //     (business: CherryPlayApi.BusinessSummary) =>
    //       business.BusinessId === businessId
    //   ) ?? null;

    // return apiResult<CherryPlayApi.BusinessSummary | null>(
    //   result,
    //   findBusinessInResults
    // );
  }

  async getMember(
    businessId: string,
    memberId: string
  ): Promise<ApiResult<CherryPlayApi.Member>> {
    const result = await this.cherryPlayClient.get(
      `/cherryplay/v1/${businessId}/members/${memberId}`
    );

    return apiResult(result, (data) => data.Results[0]);
  }

  async searchUsers(
    businessId: string,
    query: string
  ): Promise<ApiResult<CherryPlayApi.UserAccountQueryResultSetResponse>> {
    const queryParam = query.length > 0 ? query : undefined;

    const result = await this.cherryPlayClient.get(
      `/cherryplay/v1/${businessId}/user`,
      {
        params: { q: queryParam, limit: 30 },
      }
    );

    return apiResult(result);
  }

  async searchUsersPage(
    url: string
  ): Promise<ApiResult<CherryPlayApi.UserAccountQueryResultSetResponse>> {
    const result = await this.cherryPlayClient.get(url);

    return apiResult(result);
  }

  async getUser(
    businessId: string,
    userUid: string
  ): Promise<ApiResult<CherryPlayApi.UserAccount>> {
    const result =
      await this.cherryPlayClient.get<CherryPlayApi.UserAccountQueryResultSetResponse>(
        `/cherryplay/v1/${businessId}/user`,
        {
          params: { limit: 100 },
        }
      );

    const findUserInResult = (data: any) =>
      data.Results.find(
        (user: CherryPlayApi.UserAccount) => user.Uid === userUid
      );

    return apiResult(result, findUserInResult);
  }

  async createUser(
    user: { Username: string; DisplayName: string; Password?: string },
    businessId: string
  ): Promise<ApiResult<CherryPlayApi.UserAccount>> {
    const result = await this.cherryPlayClient.post(
      `/cherryplay/v1/${businessId}/user`,
      JSON.stringify(user),
      {
        headers: {
          "content-type": "application/json",
        },
      }
    );

    return apiResult(result);
  }

  async updateUserRole(
    businessId: string,
    userUid: string,
    role: string
  ): Promise<ApiResult<CherryPlayApi.UserAccount>> {
    const result = await this.cherryPlayClient.put(
      `/cherryplay/v1/${businessId}/user/${userUid}/role`,
      { Value: role }
    );

    return apiResult(result);
  }

  async updateUserAuthorisedVenues(
    businessId: string,
    userUid: string,
    authorisedVenues: string[]
  ): Promise<ApiResult<CherryPlayApi.UserAccount>> {
    const result = await this.cherryPlayClient.put(
      `/cherryplay/v1/${businessId}/user/${userUid}/authorised-venue`,
      authorisedVenues
    );

    return apiResult(result);
  }

  async deleteUser(
    businessId: string,
    userUid: string,
    userEmail: string
  ): Promise<ApiResult<CherryPlayApi.UserAccount>> {
    const result = await this.cherryPlayClient.delete(
      `/cherryplay/v1/${businessId}/user/${userUid}`,
      { params: { email: userEmail } }
    );

    return apiResult(result);
  }

  async lookupAbnRecord(
    method: string,
    query: string
  ): Promise<ApiResult<CherryPlayApi.ABNRecord[]>> {
    if (["abn", "acn", "name"].indexOf(method) === -1) {
      throw new Error(`Invalid ABN lookup type '${method}'`);
    }

    const result = await this.cherryPlayClient.get(
      `/cherryplay/v1/business/lookup/${method}`,
      { params: { q: query } }
    );

    return apiResult(result);
  }

  async createTermsConditions(
    db: Firestore,
    businessId: string,
    data: any
  ): Promise<DocumentReference<any>> {
    const collRef = collection(db, "cherryplay", businessId, "terms");
    data.businessId = businessId;
    data.createdAt = new Date();
    delete data.id;
    return addDoc(collRef, data);
  }

  async updateTermsConditions(
    db: Firestore,
    businessId: string,
    docId: string,
    data: any
  ): Promise<any> {
    data.updatedAt = new Date();
    const docRef = doc(db, "cherryplay", businessId, "terms", docId);
    return setDoc(docRef, data, { merge: true });
  }

  async getTermsConditionsSingle(
    db: Firestore,
    businessId: string,
    docId: string
  ): Promise<DocumentSnapshot> {
    const docRef = doc(db, "cherryplay", businessId, "terms", docId);
    return getDoc(docRef);
  }

  async deleteTermsConditions(
    db: Firestore,
    businessId: string,
    docId: string
  ): Promise<any> {
    const docRef = doc(db, "cherryplay", businessId, "terms", docId);
    return setDoc(docRef, { createdAt: null }, { merge: true });
  }

  async cloneTermsConditions(
    db: Firestore,
    businessId: string,
    docId: string
  ): Promise<any> {
    const srcDoc = await this.getTermsConditionsSingle(db, businessId, docId);
    const cloneData = srcDoc.data();
    if (cloneData) {
      cloneData.name = `Copy of ${cloneData.name}`;
      const fieldsToDelete = ["promoCommencement", "promoEnd"];
      fieldsToDelete.map((field) => {
        delete cloneData[field];
      });
    }
    return this.createTermsConditions(db, businessId, cloneData);
  }

  async getTermsConditionsList(
    db: Firestore,
    businessId: string
  ): Promise<QuerySnapshot<DocumentData>> {
    const collRef = collection(db, "cherryplay", businessId, "terms");
    return getDocs(
      query(
        collRef,
        where("createdAt", "!=", null),
        orderBy("createdAt", "desc")
      )
    );
  }

  async searchPromotions(
    businessId: string,
    query: string
  ): Promise<ApiResult<CherryPlayApi.PromotionQueryResult>> {
    const queryParam = query.length > 0 ? query : undefined;

    const result = await this.cherryPlayClient.get(
      `/cherryplay/v1/${businessId}/promotions`,
      {
        params: { q: queryParam, limit: 30 },
      }
    );

    return apiResult(result);
  }

  async searchPromotionsPage(
    url: string
  ): Promise<ApiResult<CherryPlayApi.PromotionQueryResult>> {
    const result = await this.cherryPlayClient.get(url);

    return apiResult(result);
  }

  async getBundles(): Promise<ApiResult<CherryPlayApi.BundleQueryResult>> {
    const result = await this.cherryPlayClient.get(`/cherryplay/v1/bundles`, {
      params: { q: undefined, limit: 0 },
    });

    return apiResult(result);
  }

  async createPromotion(
    displayName: string,
    bundleId: string,
    businessId: string
  ): Promise<ApiResult<CherryPlayApi.PromotionDocument>> {
    const promotionForm = new FormData();
    promotionForm.append("promotionName", displayName);
    promotionForm.append("bundleId", bundleId);

    const result = await this.cherryPlayClient.post(
      `/cherryplay/v1/${businessId}/promotions`,
      promotionForm,
      {
        headers: {
          "content-type": "multipart/form-data",
        },
      }
    );

    return apiResult(result);
  }
}
