import {
  Box,
  Heading,
  Text,
  OrderedList,
  UnorderedList,
  ListItem,
} from "@chakra-ui/layout";
import React, { useEffect, useState } from "react";
import { TermsConditionsFormValues } from "../../forms/TermsConditionsForm";

interface TermsConditionsDisplayProps {
  item: TermsConditionsFormValues;
}

function getListLevel(el: any) {
  const className = el.className || "0";
  return +className.replace(/[^\d]/g, "");
}

function convertLists(richtext: any) {
  const tempEl = window.document.createElement("div");
  tempEl.setAttribute("style", "display: none;");
  tempEl.innerHTML = richtext;

  ["ul", "ol"].forEach((type) => {
    const startTag = `::start${type}::::/start${type}::`;
    const endTag = `::end${type}::::/end${type}::`;

    // Grab each list, and work on it in turn
    Array.from(tempEl.querySelectorAll(type)).forEach((outerListEl) => {
      const listChildren = Array.from(outerListEl.children).filter(
        (el) => el.tagName === "LI"
      );

      // Account for the fact that the first li might not be at level 0
      const firstLi = listChildren[0];
      firstLi.before(startTag.repeat(getListLevel(firstLi)));

      // Now work through each li in this list
      listChildren.forEach((listEl, index) => {
        const currentLiLevel = getListLevel(listEl);
        if (index < listChildren.length - 1) {
          const difference =
            getListLevel(listChildren[index + 1]) - currentLiLevel;

          // we only need to add tags if the level is changing
          if (difference > 0) {
            listChildren[index + 1].before(startTag.repeat(difference));
          } else if (difference < 0) {
            listEl.after(endTag.repeat(-difference));
          }
        } else {
          listEl.after(endTag);
        }
      });
      outerListEl.after(endTag);
    });
  });

  //  Get the content in the element and replace the temporary tags with new ones
  let newContent = tempEl.innerHTML;
  newContent = newContent.replace(/::startul::::\/startul::/g, "<ul>");
  newContent = newContent.replace(/::endul::::\/endul::/g, "</ul>");
  newContent = newContent.replace(/::startol::::\/startol::/g, "<ol>");
  newContent = newContent.replace(/::endol::::\/endol::/g, "</ol>");
  newContent = newContent.replace(/ class="ql-indent-1"/g, "");
  newContent = newContent.replace(/ class="ql-indent-2"/g, "");

  tempEl.remove();
  return newContent;
}

export const TermsConditionsDisplay = ({
  item,
}: TermsConditionsDisplayProps) => {
  const [data, setData] = useState<any>({});

  useEffect(() => {
    if (item) {
      // eligibility
      let eligibility = "";
      if (item["eligibility-ALL"]) {
        eligibility = "Australian";
      } else {
        const eligibilityStates: string[] = [];
        const fields: Array<keyof TermsConditionsFormValues> = [
          "eligibility-ALL",
          "eligibility-QLD",
          "eligibility-VIC",
          "eligibility-SA",
          "eligibility-WA",
          "eligibility-NT",
          "eligibility-ACT",
        ];

        fields.map((f) => {
          const field = item[f];
          if (field === true) {
            eligibilityStates.push(f.replace("eligibility-", ""));
          }
        });

        eligibility = eligibilityStates.join(", ");
      }

      console.log("eligibility", eligibility);

      // eligibilityType
      if (item.eligibilityType === "all") {
        item.eligibilityType = `all ${eligibility} permanent residents`;
      } else {
        item.eligibilityType = `financial members of the Promoter`;
      }

      // notifyMethod
      let notifyMethod = "";
      const notifyMethods: string[] = [];
      const fields: Array<keyof TermsConditionsFormValues> = [
        "notifyMethod-Email",
        "notifyMethod-Phone",
        "notifyMethod-SMS",
        "notifyMethod-SocialMedia",
      ];

      fields.map((f) => {
        const field = item[f];
        if (field === true) {
          notifyMethods.push(f.replace("notifyMethod-", ""));
        }
      });

      notifyMethod = notifyMethods.join(", ");

      let determinationOfWinnerDesc = "";
      if (item.determinationOfWinnerDesc) {
        determinationOfWinnerDesc = convertLists(
          item.determinationOfWinnerDesc
        );
      }

      // // prizes sorting
      // item.prizes.sort((a, b) => {
      //   if (a.rank < b.rank) {
      //     return -1;
      //   }
      //   if (a.rank > b.rank) {
      //     return 1;
      //   }
      //   return 0;
      // });

      // prizesStr
      let prizesStr = (item.prizes || "")
        .map((prize) => {
          return prize.name;
        })
        .join(", ");

      setData({
        eligibility,
        determinationOfWinnerDesc,
        notifyMethod,
        prizesStr,
      });
    }
  }, [item]);

  return (
    <Box>
      <Heading size="sm" textAlign="center">
        {item.businessName ?? ""}
      </Heading>

      <Heading mt="20px" size="md" textAlign="center">
        Trade promotion - {item.gameType}
      </Heading>

      <Heading mt="20px" size="sm" textAlign="center">
        Terms &amp; Conditions
      </Heading>

      <Box mt="30px" lineHeight="30px">
        <Text fontWeight="bold" mb="10px">
          TERMS &amp; CONDITIONS OF ENTRY
        </Text>

        <OrderedList>
          <ListItem>
            <strong>Promotion</strong>: These Terms and Conditions constitute
            the terms of entry into the <strong>{item.name}</strong> competition
            (<strong>Promotion</strong>). By entering into the Promotion,
            Entrants agree to, and accept, these Terms and Conditions.
          </ListItem>

          <ListItem>
            <strong>Promoter</strong>: The Promotion is conducted by{" "}
            {item.businessName} (ACN {item.businessAbn}) of{" "}
            {item.businessAddress || "Unknown Address"} (
            <strong>Promoter</strong>).
          </ListItem>

          <ListItem>
            <strong>Eligibility</strong>: Entry is open to{" "}
            {item.eligibilityType}{" "}
            {item.ageLimit
              ? `aged ${item.ageLimit} years and over at the time
of entry `
              : " "}
            (each an Entrant). Employees, directors, officers, managers and
            other staff (and their immediate families) of the Promoter and its
            related bodies corporate, or of the agencies and companies
            associated with this Promotion (together, Promoter's Associates) are
            ineligible to enter this Promotion.
          </ListItem>

          <ListItem>
            <strong>Promotion Period</strong>:{" "}
            {!item.promoCommencement && <span>Not specified.</span>}
            {item.promoCommencement && (
              <span>
                Entry into the Promotion commences at{" "}
                {new Date(item.promoCommencement).toLocaleString("en-AU")}{" "}
                {item.promoCommencementTz} and closes at{" "}
                {new Date(item.promoEnd).toLocaleString("en-AU")}{" "}
                {item.promoEndTz} (<strong>Promotion Period</strong>).
              </span>
            )}
          </ListItem>

          <ListItem>
            <strong>Entry Method</strong>: {item.entryMethod || "Not Provided"}
          </ListItem>

          <ListItem>
            <strong>Entry Conditions:</strong>
            <OrderedList listStyleType="lower-alpha">
              <ListItem>
                The Promoter may, in its sole and absolute discretion, deem any
                incomplete, indecipherable or ineligible entry invalid.
              </ListItem>
              <ListItem>
                Entrants must enter the Promotion using their own name and must
                provide correct personal details. The Promoter may deem any
                entry invalid if the Promoter considers, in its sole and
                absolute discretion, that the Entrant has provided incorrect,
                misleading or fraudulent information.
              </ListItem>
              <ListItem>
                The Promoter reserves the right, at any time, to request that an
                Entrant promptly provide identification (including but not
                limited to proof of identity, proof of age and proof of
                residency) to verify the Entrant's identity, age, residential
                address, eligibility to enter and participate in the Promotion
                and to claim the Prize. If an Entrant refuses to produce that
                proof, or does not produce that proof, to the satisfaction of
                the Promoter (in its sole and absolute discretion), the Promoter
                may deem the Entrant ineligible to participate in the Promotion.
              </ListItem>
              <ListItem>
                The use of any automated entry software or any other mechanical
                or electronic means that allows an Entrant to enter into the
                Promotion repeatedly is prohibited. If an Entrant is found to be
                using such software or means, the Promoter reserves the right to
                deem all entries submitted by that Entrant to be invalid.
              </ListItem>
              {/* {item.thirdPartyPlatform && item.thirdPartyPlatformProvider && (
                <ListItem>
                  Each Entrant acknowledges that entry into this Promotion and
                  the use of the {item.thirdPartyPlatform} for the purposes of
                  this Promotion is subject to the terms and conditions of use
                  of {item.thirdPartyPlatformProvider}.
                </ListItem>
              )} */}
            </OrderedList>
          </ListItem>

          <ListItem>
            {item.entryRestriction === "no" && (
              <span>
                <strong>Number of entries</strong>: Entrants are permitted to
                submit multiple entries into the Promotion, subject to the
                requirement that each entry must be submitted separately and in
                accordance with the entry conditions set out in these Terms and
                Conditions, and done so within the timeframes stipulated for
                this Promotion. Entrants are only allowed one (1) major prize
                based on their leaderboard position at the conclusion of the
                competition..
              </span>
            )}
            {item.entryRestriction === "yes" && (
              <span>
                <strong>Number of entries</strong>: There is a maximum of{" "}
                {item.entryRestrictionNumber} entries per person
                {item.entryRestrictionPeriod}. The Promoter may deem any entry
                submitted by an Entrant invalid if it considers, acting
                reasonably, that the Entrant has attempted to make more than{" "}
                {item.entryRestrictionNumber} entries
                {item.entryRestrictionPeriod} into the Promotion.
              </span>
            )}
          </ListItem>

          <ListItem>
            <strong>Determination of Winner:</strong>
            {/* <Box
              className="richtext-content"
              dangerouslySetInnerHTML={{
                __html: data.determinationOfWinnerDesc || "Not set",
              }}
            ></Box> */}
            <Box>
              {item.gameType.toLowerCase() !== "game of skill" && (
                <OrderedList>
                  <ListItem>
                    The winners will be determined by the Entrant's scores in
                    respect of the Promotion game and at random during the
                    Promotion Period.
                  </ListItem>
                  <ListItem>
                    The winners will be determined in the following manner:
                    <OrderedList listStyleType="lower-alpha">
                      <ListItem>
                        During the Promotion Period, at random approximately,{" "}
                        {item.determinationMinorPrizeWinners ||
                          "(not provided)"}{" "}
                        will be awarded a Minor Prize; and
                      </ListItem>
                      <ListItem>
                        At the close of the Promotion period, All valid entries
                        will be determined by the live leaderboard (
                        <strong>Leaderboard</strong>) that records each
                        Entrant's scores in respect of the Promotion game during
                        the Promotion Period. The Entrants with the{" "}
                        {item.determinationMajorPrizeWinners ||
                          "(not provided)"}{" "}
                        on the LeaderBoard at the end of the Promotion Period
                        will be determined by the Promoter to be the winner and
                        will be awarded Major prizes as listed in Item 12 Prizes
                        in order corresponding to their position on the
                        LeaderBoard
                      </ListItem>
                    </OrderedList>
                  </ListItem>
                  <ListItem>
                    In the event that, at the end of the Promotion Period, there
                    are two or more Entrants that hold the same score, and that
                    score is the highest score on the LeaderBoard, the Promoter
                    reserves the right to{" "}
                    {item.determinationDrawOutcome ||
                      "award to the first person achieving the score."}
                    .
                  </ListItem>
                  <ListItem>
                    In the event the Winner forfeits the Prize in accordance
                    with these Terms and Conditions, the Promoter reserves the
                    right, in its sole and absolute discretion, to award a Prize
                    in the following manner:
                    <OrderedList listStyleType="lower-alpha">
                      <ListItem>
                        For a Minor Prize - to any other valid Entrant; or
                      </ListItem>
                      <ListItem>
                        For a Major Prize - to the Entrant with the second
                        highest score on the LeaderBoard. If the Entrant with
                        the second highest score on the LeaderBoard also
                        forfeits the Prize, the Promoter reserves the right, in
                        its sole and absolute discretion, to award the Prize to
                        the Entrant with the third highest score on the
                        LeaderBoard. The Promoter may, at its sole discretion,
                        continue this process until the Prize is awarded to an
                        Entrant.
                      </ListItem>
                    </OrderedList>
                  </ListItem>
                </OrderedList>
              )}

              {item.gameType.toLowerCase() === "game of skill" && (
                <Box>
                  <OrderedList>
                    <ListItem>
                      All valid entries will be determined by the live
                      leaderboard that records each Entrant’s scores in respect
                      of the Promotion game during the Promotion Period.
                    </ListItem>
                    <ListItem>
                      The winners will be determined in the following manner:
                      <OrderedList listStyleType="lower-alpha">
                        <ListItem>
                          At the close of the Promotion period, All valid
                          entries will be determined by the live leaderboard (
                          <strong>Leaderboard</strong>) that records each
                          Entrant’s scores in respect of the Promotion game
                          during the Promotion Period. The Entrants with the{" "}
                          {item.determinationMajorPrizeWinners ||
                            "(not provided)"}{" "}
                          on the LeaderBoard at the end of the Promotion Period
                          will be determined by the Promoter to be the winner
                          and will be awarded Major prizes as listed in Item 12
                          Prizes in order corresponding to their position on the
                          LeaderBoard
                        </ListItem>
                      </OrderedList>
                    </ListItem>
                    <ListItem>
                      In the event that, at the end of the Promotion Period,
                      there are two or more Entrants that hold the same score,
                      and that score is the highest score on the LeaderBoard,
                      the Promoter reserves the right to{" "}
                      {item.determinationDrawOutcome ||
                        "award to the first person achieving the score"}
                      .
                    </ListItem>
                    <ListItem>
                      In the event the Winner forfeits the Prize in accordance
                      with these Terms and Conditions, the Promoter reserves the
                      right, in its sole and absolute discretion, to award a
                      Prize in the following manner:
                      <OrderedList listStyleType="lower-alpha">
                        <ListItem>
                          to the Entrant with the second highest score on the
                          LeaderBoard. If the Entrant with the second highest
                          score on the LeaderBoard also forfeits the Prize, the
                          Promoter reserves the right, in its sole and absolute
                          discretion, to award the Prize to the Entrant with the
                          third highest score on the LeaderBoard. The Promoter
                          may, at its sole discretion, continue this process
                          until the Prize is awarded to an Entrant.
                        </ListItem>
                      </OrderedList>
                    </ListItem>
                  </OrderedList>
                </Box>
              )}
            </Box>
          </ListItem>

          <ListItem>
            <strong>Notification of Winner</strong>
            <OrderedList>
              {data.notifyMethod && (
                <ListItem>
                  The Winner will be notified by the Promoter via{" "}
                  {data.notifyMethod}
                </ListItem>
              )}
              {item["notifyMethod-Other"] && (
                <ListItem>
                  The Winner must confirm acceptance of the Prize by{" "}
                  {item.methodOfAcceptingPrize}
                </ListItem>
              )}

              {!item["notifyMethod-Other"] && (
                <ListItem>
                  The Promoter takes no responsibility for the Winner's failure
                  to receive notification from the Promoter, for example, due to
                  spam, junk e-mail or other security settings or for the
                  Winner's provision of incorrect or otherwise non-functioning
                  contact information.
                </ListItem>
              )}

              <ListItem>
                If for any reason the Promoter is unable to contact the Winner,
                or the Winner does not confirm acceptance of the Prize in
                accordance with this Clause 9, the Winner will forfeit the Prize
                and the Promoter reserves the right to award the Prize to the
                next eligible Entrant in accordance with Clause 8 above.
              </ListItem>

              {item["notifyMethod-SMS"] && (
                <ListItem>
                  The Winner must confirm acceptance of the Prize by redemption
                  of the Reward link delivered via SMS
                </ListItem>
              )}
            </OrderedList>
          </ListItem>

          <ListItem>
            <strong>Delivery of Prize: </strong> {item.deliveryOfPrizeDetails}
          </ListItem>

          <ListItem>
            <strong>Publication of winner: </strong>
            The Winner will be published{" "}
            {item.mediaPlatform
              ? `on ${item.mediaPlatform}`
              : "using the Venue website"}{" "}
            {item.publishingDate}.
          </ListItem>

          <ListItem>
            <strong>Prize:</strong>
            <OrderedList>
              <ListItem>
                {!item.prizes?.length && <span>Prizes not configured</span>}

                {item.typeOfWinner === "1-1" && !!item.prizes?.length && (
                  <span>
                    There is one (1) prize to be won, comprising{" "}
                    {item.prizes[0].name} (<strong>Prize</strong>).
                  </span>
                )}

                {item.typeOfWinner === "n-n" && !!item.prizes?.length && (
                  <Box>
                    There are {item.prizes.length} prize(s) to be won,
                    comprising the following:
                    <UnorderedList>
                      {item.prizes.map((prize, index) => {
                        return (
                          <ListItem>
                            {prize.type} Prize {index + 1}: {prize.name}
                          </ListItem>
                        );
                      })}
                    </UnorderedList>
                    <Text>
                      (each a <strong>Prize</strong> and together,{" "}
                      <strong>Prizes</strong>).
                    </Text>
                  </Box>
                )}

                {item.typeOfWinner === "1-n-n" && !!item.prizes?.length && (
                  <Box>
                    There are {item.prizes.length} prize(s) to be won,
                    comprising the following:
                    <UnorderedList>
                      {item.prizes.map((prize, index) => {
                        return (
                          <ListItem>
                            {index === 0 ? "Major" : "Minor"} Prize {index + 1}:{" "}
                            {prize.name}
                          </ListItem>
                        );
                      })}
                    </UnorderedList>
                    <Text>
                      (each a <strong>Prize</strong> and together,{" "}
                      <strong>Prizes</strong>).
                    </Text>
                  </Box>
                )}
              </ListItem>
              {!!item.prizes?.length && (
                <ListItem>
                  The total prize value is approximately {item.totalPrizeValue}.
                </ListItem>
              )}
            </OrderedList>
          </ListItem>

          <ListItem>
            <strong>Prize Restrictions:</strong> The Winner must claim the Prize
            within {item.claimTimePeriod} of being notified that the Winner has
            won the Prize. If for any reason the Winner does not claim the Prize
            by this date, the Winner will forfeit the Prize and the Promoter
            reserves the right, in its sole discretion, to award the Prize to
            the next eligible Entrant in accordance with Clause 8 above.
          </ListItem>

          <ListItem>
            <strong>General Conditions of the Prize:</strong>
            <OrderedList listStyleType="lower-alpha">
              <ListItem>
                The Promoter's decision is final and no correspondence will be
                entered into.
              </ListItem>

              {item.promoWinnerRestriction && (
                <ListItem>{item.promoWinnerRestriction}</ListItem>
              )}

              {item.isTimeLimitToUsePrize && (
                <ListItem>
                  The Winner must use the Prize within a time period specified
                  by the Promoter (which will be communicated to the Winner at
                  the time the Winner claims the Prize).
                </ListItem>
              )}

              {item.isPrizeSuppliedBy3rdParty && (
                <ListItem>
                  The value and availability of the Prize is at the sole and
                  absolute discretion of the prize supplier and is not under the
                  Promoter's control. The Promoter takes no responsibility for
                  any variation to the Prize, or the unavailability of the Prize
                  for any reason (including the inability of an Entrant to take
                  the Prize for any reason).
                </ListItem>
              )}

              {item.isPrizeSuppliedBy3rdParty && (
                <ListItem>
                  The Winner acknowledges that redemption or use of the Prize is
                  subject to the Terms and Conditions of{" "}
                  {item.prizeSupplier3rdPartyName} as provided by the Promoter.
                </ListItem>
              )}

              {item.isIndemnityRequired && (
                <ListItem>
                  The Winner may be required by the Promoter to sign an
                  indemnity or exclusion of liability form (or any other such
                  legal document) prior to claiming the Prize. If the Winner
                  refuses or fails to sign any such forms as required by the
                  Promoter, the Winner will forfeit the Prize.
                </ListItem>
              )}

              <ListItem>
                This Promotion is a{" "}
                {item.gameType.toLowerCase() === "game of skill"
                  ? "Game of Skill, and chance plays no part in determining the winner"
                  : item.gameType}
                .
              </ListItem>

              <ListItem>
                If the Prize is unavailable for any reason, the Promoter may, in
                its sole and absolute discretion, substitute the Prize (or part
                of the prize) with a Prize of equal value and/or specification.
              </ListItem>
              <ListItem>
                The Prize must be taken as offered and the Prize (or any unused
                portion of the Prize) cannot be varied, transferred or
                exchanged, nor can it be redeemed for cash.
              </ListItem>
              <ListItem>
                If for any reason a Winner is unable to claim the Prize (or does
                not use a portion of the Prize) within the time period
                stipulated by the Promoter, then the Prize (or any unused
                portion of the Prize) will be forfeited by the Winner and the
                Promoter will not provide any alternative (including cash) for
                the Prize (or any unused portion of the Prize).
              </ListItem>
              <ListItem>
                The Promoter reserves the right to invalidate any entry if the
                Promoter considers, in its sole and absolute direction, that the
                Entrant has breached any of these Terms and Conditions, tampered
                with the entry process or engaged in any unlawful or other
                improper misconduct calculated to jeopardise the fair and proper
                conduct of the Promotion. Errors and omissions may be waived at
                the Promoter's discretion. If the Promoter becomes aware after
                an Entrant has won the Prize that the Entrant has not complied
                with these Terms and Conditions, the Entrant will have no
                entitlement to claim and use the Prize, even if the Promoter has
                announced the Entrant as a winner. In these circumstances and,
                if so notified by the Promoter, the Entrant will return, refund
                or otherwise make restitution of the Prize.
              </ListItem>
            </OrderedList>
          </ListItem>

          {/* 15 */}
          <ListItem>
            <strong>Publicity:</strong> To the extent permitted by law, each
            Entrant consents to the Promoter using the Entrant's name, likeness,
            image, location and any other information submitted as part of the
            entry (including any entry) in any media for an unlimited period of
            time without further notification, remuneration or compensation for
            the purpose of promoting this Promotion (including any outcome), the
            Promoter and promoting any products manufactured, distributed and/or
            supplied by the Promoter. For the avoidance of doubt, any use of an
            Entrant's name, likeness, image, location, content of their entry
            and any other information submitted as part of the entry by the
            Promoter during or after the Promotional Period does not mean that
            the Entrant has been selected as a Winner.
          </ListItem>

          <ListItem>
            <strong>Intellectual Property Rights</strong>

            {!item.isProvideContent && <Text>Not used.</Text>}
            {item.isProvideContent && (
              <OrderedList listStyleType="lower-alpha">
                <ListItem>
                  Each entry must not include, make reference to, or otherwise
                  breach, the intellectual property rights of any person
                  (including without limitation any visible logos, drawings,
                  cartoons, images, trade marks or copyrighted material).
                </ListItem>
                <ListItem>
                  Each entrant warrants to the Promoter that each entry
                  submitted as part of this Promotion is the original creative
                  work of the Entrant, and does not infringe the intellectual
                  property rights of any third party.
                </ListItem>
                <ListItem>
                  Each Entrant agrees to indemnify and hold harmless the
                  Promoter and Promoter's Associates against all liability for
                  any losses, claims or damage that may be incurred or suffered
                  for an alleged or actual breach of a third party's
                  intellectual property rights as a result of the use of an
                  entry as contemplated by these Terms and Conditions.
                </ListItem>
                <ListItem>
                  Entrants acknowledge and accept that all entries submitted in
                  this Promotion become the property of the Promoter, and each
                  Entrant assigns to the Promoter all of their right, title and
                  interest (including copyright) in and to their entry. Entrants
                  acknowledge that they cannot use any part of their entry once
                  it has been submitted, unless they have obtained prior written
                  consent from the Promoter.
                </ListItem>
                <ListItem>
                  For the avoidance of doubt, Entrants consent to the Promoter
                  using their entry in any manner it sees fit, including to use,
                  reproduce, modify, adapt, publish and display the entry, for
                  any purpose in any media, without compensation, restriction on
                  use, attribution or liability.
                </ListItem>
                <ListItem>
                  Pursuant to the moral rights provisions contained Copyright
                  Act 1968 (Cth), in the Entrants consent expressly to the
                  Promoter's use of their entry in any manner at its sole
                  discretion, the alteration of the entry in any way, and use of
                  the entry without attributing authorship to the Entrant.
                </ListItem>
                <ListItem>
                  Entrants agree to do what is required by the Promoter (at the
                  Promoter's reasonable request and at the Promoter's cost), to
                  give effect to this Clause 16.
                </ListItem>
              </OrderedList>
            )}
          </ListItem>

          {/* 17 */}
          <ListItem>
            <strong>Liability:</strong>

            <OrderedList listStyleType="lower-alpha">
              <ListItem>
                To the extent permitted by law, Entrants agree not to bring any
                claim against the Promoter and the Promoter's Associates, and/or
                indemnifies the Promoter and the Promoter's Associates against
                any claim that may be made (including any claim made by a third
                party), in respect of any direct or indirect loss (including
                special or consequential loss), damage, expense or injury that
                is suffered or incurred by an Entrant in connection with:
                <OrderedList listStyleType="lower-roman">
                  <ListItem>any breach of these Terms and Conditions</ListItem>
                  <ListItem>
                    entry into, or participation in, this Promotion, or
                    acceptance or use of the Prize;
                  </ListItem>
                  <ListItem>
                    any technical difficulties or equipment malfunction (whether
                    or not under the Promoter's control);
                  </ListItem>
                  <ListItem>
                    any theft, unauthorised access or third party interference;
                    any entry that is late, lost, altered, damaged or
                    misdirected (whether or not after their receipt by the
                    Promoter); or
                  </ListItem>
                  <ListItem>
                    modification or cancellation of the Promotion.
                  </ListItem>
                </OrderedList>
              </ListItem>

              <ListItem>
                The Promoter has no control over communications networks or
                services and accepts no responsibility for late, lost,
                incomplete, incorrectly submitted, delayed, illegible, corrupted
                or misdirected entries, claims or correspondence whether due to
                error, omission, alteration, tampering, deletion, theft,
                destruction, transmission interruption, communications failure
                or otherwise.
              </ListItem>
            </OrderedList>
          </ListItem>

          {/* 18 */}
          <ListItem>
            <strong>Privacy:</strong>

            <OrderedList listStyleType="lower-alpha">
              <ListItem>
                By entering into this Promotion, each Entrant acknowledges and
                consents to the Promoter (and its related bodies corporate)
                accessing, collecting and using an Entrant's personal
                information in accordance with the Promoter's Privacy Policy
                available at:{" "}
                <a href={item.privacyPolicyLink} target="_blank">
                  {item.privacyPolicyLink}
                </a>
                .
              </ListItem>
              <ListItem>
                By entering into the Promotion, an Entrant consents to the
                Promoter using the Entrant's personal information for the
                purpose of administering this Promotion, including fulfillment
                of the Prize, marketing and advertising of the Promotion and
                publicity of the outcome of the Promotion (such as the
                announcement of the Winner).
              </ListItem>
              <ListItem>
                Entrants acknowledge that the Promoter may disclose an Entrant's
                personal information to third parties for the purposes of
                conducting the Promotion (including promotional, marketing and
                publicity purposes) and delivering the Prize.
              </ListItem>
            </OrderedList>
          </ListItem>

          {/* 18 */}
          <ListItem>
            <strong>General Conditions of Promotion:</strong>

            <OrderedList listStyleType="lower-alpha">
              <ListItem>
                The Promoter reserves the right to invalidate any entry if an
                Entrant is unable to or refuses or fails to take part in any
                part of this Promotion or where an Entrant or entry is
                considered by the Promoter, acting reasonably, not to comply
                with these Terms and Conditions.
              </ListItem>

              {item.nameOfPartyConductingPromotion && (
                <ListItem>
                  Entrants acknowledge that this Promotion is in no way
                  sponsored, endorsed or administered by, or associated with,{" "}
                  {item.nameOfPartyConductingPromotion}. Entrants understand
                  that they are providing their information to the Promoter and
                  not to {item.nameOfPartyConductingPromotion}. The information
                  an Entrant provides will only be used for the purposes
                  outlined in these Terms and Conditions. Any questions,
                  comments or complaints about this Promotion must be directed
                  to the Promoter and not to{" "}
                  {item.nameOfPartyConductingPromotion}. To the extent permitted
                  by law, Entrants agree to release{" "}
                  {item.nameOfPartyConductingPromotion} against any and all
                  losses, actions, claims, costs, expenses and damages (of any
                  nature) which may be incurred or suffered by the Entrant in
                  connection with their participation in this Promotion.
                </ListItem>
              )}

              <ListItem>
                If this Promotion is interfered with in any way or is not
                capable of being conducted as anticipated due to any reason
                beyond the reasonable control of the Promoter, including, but
                not limited to, by reason of war, terrorism, state of emergency
                or disaster (including natural disaster), COVID-19 (or other
                pandemic) restrictions, infection by computer virus, telephone
                network failure, bugs, tampering, unauthorised intervention,
                fraud, technical failures or anything which corrupts or affects
                the administration, security, fairness, integrity or proper
                conduct of this Promotion, the Promoter reserves the right, in
                its sole and absolute discretion and to the fullest extent
                permitted by law, to:
                <OrderedList listStyleType="lower-roman">
                  <ListItem>
                    modify, suspend, terminate or cancel the promotion, as
                    appropriate; or
                  </ListItem>
                  <ListItem>invalidate any entry.</ListItem>
                </OrderedList>
              </ListItem>

              <ListItem>
                Failure of the Promoter to enforce any of its rights at any
                stage does not constitute a waiver of those rights.
              </ListItem>
              <ListItem>
                Entrants agree that these Terms and Conditions will be governed
                by the laws of the State of New South Wales.
              </ListItem>
            </OrderedList>
          </ListItem>

          <ListItem>
            <strong>Contact: </strong>
            {item.businessName} (ACN {item.businessAbn}) of{" "}
            {item.businessAddress || "Unknown Address"}.<br />
            Email:{" "}
            <a href={`mailto:${item.promoterEmail}`}>{item.promoterEmail}</a>
          </ListItem>

          {/* end */}
        </OrderedList>
      </Box>
    </Box>
  );
};
