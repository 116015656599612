import React from "react";
import { Box, Image, Heading, Text } from "@chakra-ui/react";

interface RoundedBundleProps {
  imageUrl: string;
  name: string;
  description: string | null | undefined;
}

export const RoundedBundle = ({
  imageUrl,
  name,
  description,
}: RoundedBundleProps) => {
  return (
    <Box
      backgroundColor="#f2f2f2"
      display="flex"
      flexDirection="column"
      alignItems="center"
      width="300px"
      borderRadius="10px"
      _hover={{
        boxShadow: "0 0 5px rgba(0, 0, 0, 0.3)",
        transition: "box-shadow 0.3s",
        background: "white",
        cursor: "pointer",
      }}
    >
      <Box flex="1">
        <Image src={imageUrl} alt="Image" borderRadius="5px" />
      </Box>
      <Box flex="2" padding="20px">
        <Heading size="md" marginBottom="10px">
          {name}
        </Heading>
        <Text fontSize="14px">{description}</Text>
      </Box>
    </Box>
  );
};
