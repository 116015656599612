import React, { useCallback } from "react";
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/modal";
import { CherryPlayApi } from "../../api/cherryplayModels";
import { useApiClient } from "../../hooks/useApiClient";
import { useToast } from "../../hooks/useToast";
import { NewPromotionForm } from "../../forms/NewPromotionForm";
import { useNavigate } from "react-router-dom";

interface NewPromotionProps {
  isOpen: boolean;
  bundleId: string;
  bundleName: string;
  businessId: string;
  onClose: () => void;
}

export const NewPromotionModal = ({
  isOpen,
  bundleId,
  bundleName,
  businessId,
  onClose,
}: NewPromotionProps) => {
  const apiClient = useApiClient();
  const navigate = useNavigate();

  const { showSuccessToast, showErrorToast } = useToast();

  const createSuccessToast = useCallback(
    () => showSuccessToast("Promotion created"),
    [showSuccessToast]
  );

  const createFailureToast = useCallback(
    (message?: string) =>
      showErrorToast(message ?? "Promotion creation failed."),
    [showErrorToast]
  );

  const onCreateSuccess = useCallback(
    (user: CherryPlayApi.PromotionDocument) => {
      createSuccessToast();
      navigate(`/businesses/${businessId}/promotions`);
    },
    []
  );

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>New Promotion</ModalHeader>
        <ModalBody pb="6">
          <NewPromotionForm
            apiClient={apiClient}
            bundleId={bundleId}
            bundleName={bundleName}
            businessId={businessId}
            onCancel={onClose}
            onSuccess={onCreateSuccess}
            onFailure={createFailureToast}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
