import React from "react";
import { FormikProps, withFormik } from "formik";
import { HStack } from "@chakra-ui/layout";
import { Button } from "@chakra-ui/button";
import { ApiClient } from "../api/apiClient";
import { isEmptyStr } from "../util/isEmptyStr";
import { TextField } from "../components/fields/TextField/TextField";
import { FormStack } from "../components/FormStack/FormStack";
import { CherryPlayApi } from "../api/cherryplayModels";

interface NewPromotionFormValues {
  name: string;
}

interface NewPromotionFormProps {
  apiClient: ApiClient;
  bundleId: string;
  bundleName: string;
  businessId: string;
  onCancel: () => void;
  /** Callback to be invoked once the new promotion account has been created */
  onSuccess?: (promotion: CherryPlayApi.PromotionDocument) => void;
  onFailure?: (message?: string) => void;
}

const InnerForm = ({
  isSubmitting,
  isValid,
  onCancel,
}: NewPromotionFormProps & FormikProps<NewPromotionFormValues>) => {
  return (
    <FormStack>
      <TextField name="name" label="Name" placeholder="Name" />

      <HStack width="100%" justifyContent="end" spacing="3" pt="8">
        <Button
          isLoading={isSubmitting}
          colorScheme="cherryButton"
          color="#fff"
          type="submit"
          disabled={isSubmitting || !isValid}
        >
          Create
        </Button>
        <Button disabled={isSubmitting} onClick={onCancel}>
          Cancel
        </Button>
      </HStack>
    </FormStack>
  );
};

export const NewPromotionForm = withFormik<
  NewPromotionFormProps,
  NewPromotionFormValues
>({
  mapPropsToValues: ({ bundleName }) => {
    return {
      name: bundleName,
    };
  },

  handleSubmit: async (values, { props, setFieldError }) => {
    let isInvalid = false;
    if (isEmptyStr(values.name)) {
      setFieldError("name", "Name is required");
      isInvalid = true;
    }

    if (isInvalid) {
      return;
    }

    const result = await props.apiClient.createPromotion(
      values.name,
      props.bundleId,
      props.businessId
    );

    if (result.ok) {
      if (props.onSuccess) {
        props.onSuccess(result.data);
      }
    } else {
      if (props.onFailure) {
        props.onFailure(result.message);
      }
    }
  },

  validateOnMount: false,
  validateOnBlur: true,
})(InnerForm);
